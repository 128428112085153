import React from "react"
import Slider from "react-slick";
import Layout from "../components/layout"
import {graphql, Link} from 'gatsby'
import {Helmet} from "react-helmet"

export const query = graphql`
  query AboutUsPageQuery {
    allJobs(
       sort: {order: ASC, fields: pickupOrder},
       filter: {isPickup: {eq: true}},
       limit: 4
     ) {
       edges {
         node {
           id
           url
           department
           title
           title_en
           description
           logo
         }
       }
    }
  }
`

const AboutUs = ({data}) => {

  const jobNodes = data.allJobs.edges;

  const sliderSetting = {
   slidesToShow: 3,
   slidesToScroll: 1,
   speed: 300,
   variableWidth: true,
   focusOnSelect: true,
   initialSlide: 1,
   responsive: [
     {
       breakpoint: 900,
       settings: {
         arrows: false,
         centerMode: true,
         slidesToShow: 3
       }
     },
     {
       breakpoint: 737,
       settings: {
         arrows: false,
         infinite: false,
         centerMode: true,
         initialSlide: 2,
         slidesToShow: 1
       }
     }
   ]
  };

  return (
    <Layout mainId={"pageAbout"} selectedMenu={"about_us"}>
        <Helmet>
          <title>LUCHE GROUP | About Us</title>
          <meta name="description" content="LUCHE GROUPは、テクノロジーを用いてギフト領域の再定義・再構築・最適化を志向し、世の中にMore Smilesを生み出せるテクノロジープラットフォームを展開していきます。既に訪問ユーザー数では日本最大級となっておりますが、グローバルNo.1ギフトプラットフォーマーとなり、新たな市場を作り上げていきたいと思っております。" />
          <link rel="canonical" href="https://luchegroup.com/about_us/" />
          <script async  src="//speakerdeck.com/assets/embed.js"></script>
        </Helmet>
        <div id="keyv" className="keyv__about">
          <div className="key__image reset--keyv" />
          <div className="inner">
            <div className="key__text">
              <h2>We are<br />
                Growing<br />
                Globally</h2>
              <p className="txt--keyv">アジア圏ギフト領域<br />
                NO.1プラットフォーマーを<br />
                目指した事業展開</p>
            </div>
          </div>
        </div>
        {/* / #keyv */}
        <div id="contents">
          <div className="sect--companies mb--80">
            <div className="inner">
              <h2 className="headline--1">Companies</h2>
              <p className="txt--common txt-center">LUCHE GROUPは、テクノロジーを用いてギフト領域の再定義・再構築・最適化を志向し、<br />
                世の中にMore Smilesを生み出せるテクノロジープラットフォームを展開していきます。<br />
                既に訪問ユーザー数では日本最大級となっておりますが、グローバルNo.1ギフトプラットフォーマーとなり、<br />
                新たな市場を作り上げていきたいと思っております。</p>
              <Slider {...sliderSetting} className="swiper--container">
                <div className="swiper--item">
                  <div className="companny--blog-hdg">
                    <p className="companny--blog-logo"><img src="/images/common/logo_lucheholdings.png" alt="LUCHE HOLDINGS PTE. LTD." /></p>
                    <h3 className="companny--blog-title">LUCHE HOLDINGS PTE. LTD.</h3>
                  </div>
                  <div className="companny--blog-info">
                    <p className="blog--info-title">設立<span>2013年11月</span></p>
                    <p className="blog--info-title">事業内容<span>プレゼント・ギフト領域特化型メディア事業の運営<br /><br /><br /><br /></span></p>
                    <p className="blog--info-title">Headquarters<span>SINGAPORE<br />
                        26 SERANGOON NORTH AVENUE 18F</span></p>
                  </div>
                </div>
                <div className="swiper--item">
                  <div className="companny--blog-hdg">
                    <p className="companny--blog-logo"><img src="/images/common/logo_gift.svg" alt="株式会社ギフトモール" /></p>
                    <h3 className="companny--blog-title">株式会社ギフトモール</h3>
                  </div>
                  <div className="companny--blog-info">
                    <p className="blog--info-title">設立<span>2014年8月</span></p>
                    <p className="blog--info-title">事業内容<span>プレゼント・ギフト領域特化型マーケットプレイスプラットフォームの運営<br /><br /><br /></span></p>
                    <p className="blog--info-title">Headquarters<span>〒103-0028 東京都中央区八重洲1-4-10 東京建物八重洲仲通りビル12階</span></p>
                  </div>
                </div>
                <div className="swiper--item">
                  <div className="companny--blog-hdg">
                    <p className="companny--blog-logo"><img src="/images/common/text_magical.svg" alt="MagicalTrip株式会社" /></p>
                    <h3 className="companny--blog-title">MagicalTrip株式会社</h3>
                  </div>
                  <div className="companny--blog-info">
                    <p className="blog--info-title">設立<span>2019年8月</span></p>
                    <p className="blog--info-title">事業内容<span>海外旅行者向けローカルガイドサービスを展開する「MagicalTrip」の運営、お祝い体験予約プラットフォーム開発</span></p>
                    <p className="blog--info-title">Headquarters<span>〒103-0028 東京都中央区八重洲1-4-10 東京建物八重洲仲通りビル12階 </span></p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="sect--culture mb--80">
            <div className="inner">
              <h2 className="headline--1">Our Culture</h2>
              <div className="block--info">
                <p className="box--image"><img src="/images/about/img-remort_work.jpg" alt="Remote Work リモートワークを前提に" /></p>
                <div className="box-text">
                  <h3 className="headline--2">Remote Work<span className="sub">リモートワークを前提に</span></h3>
                  <p className="txt--common-01">LUCHE GROUPは、働くメンバー各人の希望に応じて、場所や時間にとらわれない柔軟な働き方を実現します。チームがシンガポールと日本に分かれているからこそ、「リモートワークだから実現できる労働環境」を提供すべく、会議体やツールの改善を日々重ねています（現在、メンバーは5ヶ国に在住しております。）<br />もちろん、オフィスで働くことも歓迎します。</p>
                </div>
              </div>
              <div className="block--info row--reverse">
                <p className="box--image"><img src="/images/about/img-we_are_global.jpg" alt="We are Globa グローバルな事業をグローバルな環境で" /></p>
                <div className="box-text">
                  <h3 className="headline--2">We are Global<span className="sub">グローバルな事業をグローバルな環境で</span></h3>
                  <p className="txt--common-01">LUCHE GROUPでは国内外に向けたWEBメディアの発信と事業の展開をしています。<br />
                    また拠点が日本とシンガポールにあり、それぞれの拠点で働いているメンバーがいます。<br />
                    1年はシンガポールで、その後は東京でなど、拠点を柔軟に選択しているメンバーもいます。</p>
                </div>
              </div>
              <div className="block--info">
                <p className="box--image"><img src="/images/about/img-professionals.jpg" alt="Professionals ひとりひとりがプロフェッショナル" /></p>
                <div className="box-text">
                  <h3 className="headline--2">Professionals<span className="sub">ひとりひとりがプロフェッショナル</span></h3>
                  <p className="txt--common-01">LUCHE GROUPではチームの中で役割の違いはありますが、上下関係はありません。<br />
                    誰もが良いと思ったことを、人間関係を気にせずに、すぐに実行できる状況にあります。<br />
                    全員がお互いをプロフェッショナルとして尊敬しているからこそ、成立しています。</p>
                </div>
              </div>
              <div className="block--info row--reverse">
                <p className="box--image"><img src="/images/about/img-embrace_change.jpg" alt="Embrace Change 大きな変化を楽しむ" /></p>
                <div className="box-text">
                  <h3 className="headline--2">Embrace Change<span className="sub">大きな変化を楽しむ</span></h3>
                  <p className="txt--common-01">
						LUCHE GROUPの事業は常に変化と成長を遂げています。<br />
						事業の成長に応じてメンバー各々の仕事も日々変革、成長を求められます。 <br />
						この変化を一緒に楽しみながら次の事業を一緒につくってくれる仲間を求めています。
				  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sect--deck">
            <div className="inner">
              <h2 className="headline--1">Culture Deck</h2>
              <p className="txt--common txt-center">現在の事業の概要、LUCHE GROUPが大切にしている価値観、採用についての考え方、<br />
                人事制度や働き方などを紹介しています。<br />
                内容は随時最新版に公開されています。ぜひご一読ください。</p>
                <div className="speakerdeck-embed" data-id="1c7a25f65f3d475686c0414fec025442" data-ratio="1.77777777777778" src="//speakerdeck.com/assets/embed.js"></div>
            </div>
          </div>
          <div className="sect--join-us">
            <div className="inner">
              <h2 className="headline--1">Join Us!</h2>
              <p className="txt--common txt-center">私たちと一緒に、新しいワクワクに向かって挑戦してみませんか？</p>
              <div className="block--scroll">
                <div className="content--join">
                  {
                    jobNodes.map((jobNode) => { 
                      const job = jobNode.node
                      return (
                        <a key={job.id} href={job.url}  rel="noreferrer" target="_blank" className="content--join-item">
                          <div className="content--join-hdg">
                            <p className="content--hdg-sub">{job.department}</p>
                            <h4 className="content--hdg-title">{job.title}<span>{job.title_en}</span></h4>
                            <p className="content--hdg-text">{job.description}</p>
                          </div>
                          <div className="content--join-logo">
                            <p><img src={job.logo} alt="Giftmall" /><span className="content--join-icon"><img src="/images/common/icon_arrow.svg" alt="" /></span></p>
                          </div>
                        </a>
                      );

                    })
                  }
                </div>
              </div>
              <p className="txt--center"><Link to="/careers" className="key__button sect--btn hover">Careers<img src="/images/common/icon_arrow.svg" alt="" /></Link></p>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default AboutUs
